
import { Day } from '@/enums/Day';
import Order from '@/Interfaces/Order';
import router from '@/router';
import DatePicker from '@/shared/DatePicker.vue';
import RoleService from '@/services/RoleService';
import SearchService from '@/services/SearchService';
import OrderService from '@/services/OrderService';
import LocalStorageService from '@/services/LocalStorageService';
import { TransactionType } from '@/enums/TransactionType';
import { PaymentType } from '@/enums/PaymentType';

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import GtagService from '@/services/GtagService';

import { isEmpty } from 'lodash';
import Pagination from '@/shared/Pagination.vue';
import { defineComponent } from 'vue';
import Location from '@/Interfaces/Location';

interface ComponentData {
  lastName: string;
  firstName: string;
  textCount: number;
  searchTimeout: number;
  ArrowAscDate: number;

  searchEnable: boolean;
  applyEnable: boolean;
  ArrowAscFirstName: number;
  ArrowAscLastName: number;
  ArrowAscLocation: number;

  fromdate: string | null;
  selectedDateRange: string | null;
  dataRangeOptios: Array<{ value: string | null; text: string }>;
  datepickerSetting: {
    id: string;
    name: string;
    class: string;
    yearMinus: number;
    value: string;
    locale: {
      weekday: string[];
      todayBtn: string;
      clearBtn: string;
      closeBtn: string;
    };
    changeEvent: (value: string) => void;
  };
  todate: string | null;
  todatepickerSetting: {
    id: string;
    name: string;
    class: string;
    yearMinus: number;
    value: string;
    locale: {
      weekday: string[];
      todayBtn: string;
      clearBtn: string;
      closeBtn: string;
    };
    changeEvent: (value: string) => void;
  };

  gtagService: GtagService;
  roleService: RoleService;
  orderService: OrderService;
  searchService: SearchService;
  localStorageService: LocalStorageService;

  orders: Order[];
  filteredOrders: Order[];
  transactionType: TransactionType;
  paymentType: PaymentType;
  isApplyButtonActive: boolean;
  searchTerm: string;
  loading: boolean;
  locs: Location[];
  locations: Location[];
  locationOptions: any[];
  locationOptionsNew: any[];
  locationdropdown: any;
  selectedLocationId: string;
  selectedLocationIds: string[] | null;
  page: number;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  nextPage: number;
  previusePage: number;
  noData: boolean;
  records: number;
  alertTitle: string;
  alertMessage: string;
  showConfirm: boolean;
}

export default defineComponent({
  components: {
    DatePicker,
    Pagination,
  },
  data() {
    const data: ComponentData = {
      lastName: '',
      firstName: '',
      textCount: 0,
      searchTimeout: undefined,
      ArrowAscDate: 1,

      searchEnable: true,
      applyEnable: true,
      ArrowAscFirstName: 0,
      ArrowAscLastName: 0,
      ArrowAscLocation: 0,

      fromdate: null,
      selectedDateRange: null,
      dataRangeOptios: [
        { value: null, text: 'Select Date Range' },
        { value: 'Today', text: 'Today' },
        { value: 'Yesterday', text: 'Yesterday' },
        { value: 'This Week', text: 'This Week' },
        { value: 'Last Week', text: 'Last Week' },
        { value: 'This Month', text: 'This Month' },
        { value: 'Last Month', text: 'Last Month' },
      ],
      datepickerSetting: undefined,
      todate: null,
      todatepickerSetting: undefined,
      gtagService: undefined,
      roleService: undefined,
      orderService: undefined,
      searchService: undefined,
      localStorageService: undefined,
      orders: [],
      filteredOrders: [],
      transactionType: undefined,
      paymentType: undefined,
      isApplyButtonActive: true,
      searchTerm: '',
      loading: false,
      locs: [],
      locations: [],
      locationOptions: [],
      locationOptionsNew: [],
      locationdropdown: null,
      selectedLocationId: undefined,
      selectedLocationIds: null,
      page: 1,
      pageSize: 15,
      currentPage: 1,
      totalCount: 0,
      nextPage: 0,
      previusePage: 0,
      noData: true,
      records: 0,
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
    };
    return data;
  },
  created() {
    this.gtagService = new GtagService();
    this.roleService = new RoleService(new LocalStorageService());
    this.orderService = new OrderService();
    this.searchService = new SearchService();
    this.localStorageService = new LocalStorageService();
    this.roleService.getRole();
    this.datepickerSetting = {
      id: 'fromdateId',
      name: 'fromdateName',
      class: 'myDateInput',
      value: '',
      placeholder: 'From Date',
      yearMinus: '0',
      locale: {
        weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        todayBtn: 'Today',
        clearBtn: 'Clear',
        closeBtn: 'Close',
      },
      changeEvent: (value: any) => {
        //From
        this.fromdate = value;
        if (
          this.todate != '' &&
          this.todate != null &&
          this.todate != undefined &&
          moment(this.todate) &&
          moment(value) > moment(this.todate)
        ) {
          this.alertTitle = 'Alert';
          this.alertMessage = 'FromDate should be smaller than the ToDate';
          this.showConfirm = true;
          this.selectedDateRange = this.dataRangeOptios[0].value;
          return;
        }
        this.isApplyButtonActive = true;
        this.resetDateRangeSelector();
      },
    };
    this.todatepickerSetting = {
      id: 'todateId',
      name: 'todateName',
      class: 'myDateInput',
      yearMinus: '0',
      placeholder: 'To Date',
      value: '',
      locale: {
        weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        todayBtn: 'Today',
        clearBtn: 'Clear',
        closeBtn: 'Close',
      },
      changeEvent: (value: any) => {
        //ToDate
        this.todate = value;
        if (
          this.fromdate != '' &&
          this.fromdate != null &&
          this.fromdate != undefined &&
          moment(this.fromdate) &&
          moment(this.fromdate) > moment(value)
        ) {
          this.alertTitle = 'Alert';
          this.alertMessage = 'ToDate should be greater than the FromDate';
          this.showConfirm = true;
          this.selectedDateRange = this.dataRangeOptios[0].value;
          return;
        }
        this.isApplyButtonActive = true;
        this.resetDateRangeSelector();
      },
    };
    this.transactionType = TransactionType;
    this.paymentType = PaymentType;
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
  },
  async mounted() {
    this.loading = true;
    // this.gtagService.addEvent("list transactions");
    this.$store.dispatch('getLocationList').then(() => {
      this.locs = this.$store.getters.locatios;
    });
    await this.$store.dispatch('getLocationList');
    this.locations = this.$store.getters.allLocationList;
    this.setLocationOptions();
    if (!this.roleService.isSupport && !this.roleService.isSuperadmin) {
      const filter = this.$store.getters.getOrderListFilter;
      filter.startDate = '';
      filter.endDate = '';
      filter.page = 1;
      filter.pageSize = 15;
      filter.search = '';
      filter.searchField = '';
      filter.orderBy = 'DESC';
      filter.orderByField = '';
      filter.lastName = null;
      filter.firstName = null;
      filter.locationId = this.selectedLocationId;
      this.$store.dispatch('setOrderListFilter', filter).then(() => {
        this.getAllOrders(null, this.page);
      });
    }

    if (!(this.roleService.isSupport || this.roleService.isSuperadmin)) {
      this.searchEnable = false;
      this.applyEnable = false;
    }

    if (
      this.fromdate === null ||
      this.fromdate === '' ||
      this.todate === null ||
      this.todate === ''
    ) {
      this.applyEnable = true;
    }

    this.loading = false;
  },
  methods: {
    ...mapActions([
      'getTransactions',
      'setTransactionFilter',
      'getLocationList',
      'setOrderListFilter',
      'getOrderList',
    ]),

    goToPage(payload) {
      this.getAllOrders(payload.index, payload.page);
    },

    resetDateRangeSelector() {
      if (isEmpty(this.fromdate?.trim()) || isEmpty(this.todate?.trim())) {
        this.selectedDateRange = this.dataRangeOptios[0].value;
        return;
      }
      const today = new Date(moment().format('MM/DD/YYYY'));
      const yesterday = new Date(
        moment().subtract(1, 'day').format('MM/DD/YYYY'),
      );
      const fromDateThisWeek = new Date(
        moment().startOf('week').format('MM/DD/YYYY'),
      );
      const toDateThisWeek = new Date(
        moment().endOf('week').format('MM/DD/YYYY'),
      );
      const fromDateLastWeek = new Date(
        moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY'),
      );
      const toDateLastWeek = new Date(
        moment().subtract(1, 'weeks').endOf('week').format('MM/DD/YYYY'),
      );
      const fromDateThisMonth = new Date(
        moment().startOf('months').format('MM/DD/YYYY'),
      );
      const toDateThisMonth = new Date(
        moment().endOf('months').format('MM/DD/YYYY'),
      );
      const fromDateLastMonth = new Date(
        moment().subtract(1, 'months').startOf('months').format('MM/DD/YYYY'),
      );
      const toDateLastMonth = new Date(
        moment().subtract(1, 'months').endOf('months').format('MM/DD/YYYY'),
      );

      const toDate = new Date(this.todate);
      const fromDate = new Date(this.fromdate);

      // today 1
      if (+toDate === +today && +fromDate === +today) {
        this.selectedDateRange = this.dataRangeOptios[1].value;
        return;
      }
      // yesterday 2
      if (+toDate === +yesterday && +fromDate === +yesterday) {
        this.selectedDateRange = this.dataRangeOptios[2].value;
        return;
      }
      // this week 3
      if (+fromDate == +fromDateThisWeek && +toDate == +toDateThisWeek) {
        this.selectedDateRange = this.dataRangeOptios[3].value;
        return;
      }
      // last week 4
      if (+fromDate === +fromDateLastWeek && +toDate === +toDateLastWeek) {
        this.selectedDateRange = this.dataRangeOptios[4].value;
        return;
      }
      // this month 5
      if (+fromDate === +fromDateThisMonth && +toDate === +toDateThisMonth) {
        this.selectedDateRange = this.dataRangeOptios[5].value;
        return;
      }
      // last month 6
      if (+fromDate === +fromDateLastMonth && +toDate === +toDateLastMonth) {
        this.selectedDateRange = this.dataRangeOptios[6].value;
        return;
      }
      this.selectedDateRange = this.dataRangeOptios[0].value;
    },

    changeDateRange(dateRange: any) {
      console.log('changeDateRange', this.selectedDateRange);
      if (dateRange === 'Today') {
        const todayDate = moment().format('MM-DD-YYYY');
        this.datepickerSetting.value = todayDate;
        this.datepickerSetting.placeholder = todayDate;
        this.fromdate = todayDate;
        this.todatepickerSetting.value = todayDate;
        this.todatepickerSetting.placeholder = todayDate;
        this.todate = todayDate;
      } else if (dateRange === 'Yesterday') {
        const yesterdayDate = moment().subtract(1, 'day').format('MM-DD-YYYY');
        this.datepickerSetting.value = yesterdayDate;
        this.datepickerSetting.placeholder = yesterdayDate;
        this.fromdate = yesterdayDate;
        this.todatepickerSetting.value = yesterdayDate;
        this.todatepickerSetting.placeholder = yesterdayDate;
        this.todate = yesterdayDate;
      } else if (dateRange === 'This Week') {
        const fromDateThisWeek = moment().startOf('week').format('MM-DD-YYYY');
        const toDateThisWeek = moment().endOf('week').format('MM-DD-YYYY');
        this.datepickerSetting.value = fromDateThisWeek;
        this.datepickerSetting.placeholder = fromDateThisWeek;
        this.fromdate = fromDateThisWeek;
        this.todatepickerSetting.value = toDateThisWeek;
        this.todatepickerSetting.placeholder = toDateThisWeek;
        this.todate = toDateThisWeek;
      } else if (dateRange === 'Last Week') {
        const fromDateLastWeek = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('MM-DD-YYYY');
        const toDateLastWeek = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format('MM-DD-YYYY');
        this.datepickerSetting.value = fromDateLastWeek;
        this.datepickerSetting.placeholder = fromDateLastWeek;
        this.fromdate = fromDateLastWeek;
        this.todatepickerSetting.value = toDateLastWeek;
        this.todatepickerSetting.placeholder = toDateLastWeek;
        this.todate = toDateLastWeek;
      } else if (dateRange === 'This Month') {
        const fromDateThisMonth = moment()
          .startOf('months')
          .format('MM-DD-YYYY');
        const toDateThisMonth = moment().endOf('months').format('MM-DD-YYYY');
        this.datepickerSetting.value = fromDateThisMonth;
        this.datepickerSetting.placeholder = fromDateThisMonth;
        this.fromdate = fromDateThisMonth;
        this.todatepickerSetting.value = toDateThisMonth;
        this.todatepickerSetting.placeholder = toDateThisMonth;
        this.todate = toDateThisMonth;
      } else if (dateRange === 'Last Month') {
        const fromDateLastMonth = moment()
          .subtract(1, 'months')
          .startOf('months')
          .format('MM-DD-YYYY');
        const toDateLastMonth = moment()
          .subtract(1, 'months')
          .endOf('months')
          .format('MM-DD-YYYY');
        this.datepickerSetting.value = fromDateLastMonth;
        this.datepickerSetting.placeholder = fromDateLastMonth;
        this.fromdate = fromDateLastMonth;
        this.todatepickerSetting.value = toDateLastMonth;
        this.todatepickerSetting.placeholder = toDateLastMonth;
        this.todate = toDateLastMonth;
      }
    },

    getDateFormat(val: Day) {
      const date = new Date();
      let return_day = date;
      if (val === Day.FirstDayOfMonth) {
        return_day = new Date(date.getFullYear(), date.getMonth(), 1);
      } else if (val === Day.LastDayOfMonth) {
        return_day = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      } else if (val === Day.OtherDayOfMonth) {
        return_day = date;
      }
      return (
        (return_day.getMonth() > 8
          ? return_day.getMonth() + 1
          : '0' + (return_day.getMonth() + 1)) +
        '/' +
        (return_day.getDate() > 9
          ? return_day.getDate()
          : '0' + return_day.getDate()) +
        '/' +
        return_day.getFullYear()
      );
    },

    displayNegativeCurrency(cellValue: number) {
      return {
        negative: cellValue < 0,
      };
    },

    formatDate(date: any) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },

    searchWithDateRange() {
      const filter = this.$store.getters.getOrderListFilter;
      filter.startDate =
        this.fromdate === null || this.fromdate === ''
          ? null
          : this.formatDate(this.fromdate);
      filter.endDate =
        this.todate === null || this.todate === ''
          ? null
          : this.formatDate(this.todate);
      filter.lastName =
        this.lastName.trim() !== '' ? this.lastName.trim() : null;
      filter.firstName =
        this.firstName.trim() !== '' ? this.firstName.trim() : null;
      this.$store.dispatch('setOrderListFilter', filter).then(() => {
        this.getAllOrders(null, 1);
      });
    },

    changeLocation() {
      this.localStorageService.store(
        'primary_location_id',
        this.selectedLocationId,
      );
      this.orders = [];
      this.filteredOrders = [];

      const filter = this.$store.getters.contactFilter;
      filter.locationId = this.selectedLocationId;
      this.$store.dispatch('setOrderListFilter', filter).then(() => {
        this.getAllOrders(null, this.page);
      });
    },

    async callbackMe() {
      await this.changeLocation();
    },

    dropDownBox() {
      this.locationOptionsNew = [];
      this.$store.dispatch('getLocationList').then(() => {
        this.locationOptions = this.$store.getters.locations;
        for (let i = 0; i < this.locationOptions.length; i++) {
          this.locationOptionsNew.push({
            html: this.locationOptions[i].name,
            value: this.locationOptions[i].id,
          });
        }
        // @ts-ignore
        this.locationdropdown = new Drop({
          selector: '#myMulti',
          options: this.locationOptionsNew,
          preselected: [],
          selected: [],
          callbackfunction: this.callbackMe,
        });
        // myDrop.toggle();
      });
    },

    selected(order: Order) {
      this.$router.push(`/orders/${order.id}`);
    },

    getPaymentType(type: number, orderPayment: any) {
      if (orderPayment.length > 1) return 'Split Payment';
      else if (type === PaymentType.Cash) return 'Cash';
      else if (type === PaymentType.CreditCard) return 'Credit Card';
      else if (type === PaymentType.Ach) return 'ACH';
      else if (type === PaymentType.None) return 'None';
      else return '';
    },

    selectedOrderHandler(record: Order) {
      router.push({ path: `/orders/${record.id}` });
    },

    SearchByTerm() {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        const filter = this.$store.getters.getOrderListFilter;
        filter.startDate =
          this.fromdate === null || this.fromdate === ''
            ? null
            : this.formatDate(this.fromdate);
        filter.endDate =
          this.todate === null || this.todate === ''
            ? null
            : this.formatDate(this.todate);
        filter.lastName =
          this.lastName.trim() !== '' ? this.lastName.trim() : null;
        filter.firstName =
          this.firstName.trim() !== '' ? this.firstName.trim() : null;
        filter.search = this.searchTerm;
        this.$store.dispatch('setOrderListFilter', filter).then(() => {
          this.getAllOrders(null, 1, undefined, undefined, temp);
        });
      }, 1000);
    },

    async conductSearch() {
      // if (this.searchTerm.length > 2 || this.searchTerm.length === 0) {
      this.loading = true;
      const fromDate = this.generateDateFilterString(this.fromdate);
      const toDate = this.generateDateFilterString(this.todate);
      this.filteredOrders = await this.searchService.searchAndFilterOrders(
        this.orders,
        this.searchTerm,
        fromDate,
        toDate,
      );
      if (this.filteredOrders) {
        this.loading = false;
      }
      // }
    },

    generateDateFilterString(dateFilter: Date | null) {
      if (!dateFilter) {
        return '';
      }
      return dateFilter.toLocaleString();
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
      if (this.roleService.isStaffOrAdmin) {
        const locationOfUsers = this.localStorageService.getItem('locations');
        if (locationOfUsers != null) {
          const _locationOfUsers = JSON.parse(locationOfUsers);
          if (_locationOfUsers.length === 1) {
            this.selectedLocationId =
              _locationOfUsers[0].id ||
              this.localStorageService.getItem('primary_location_id');
          }
        }
      }
    },

    handleSorting(para1: any, para2: any) {
      this.ArrowAscDate = 0;
      this.ArrowAscFirstName = 0;
      this.ArrowAscLastName = 0;
      this.ArrowAscLocation = 0;
      if (para1 === 'date') {
        this.ArrowAscDate = para2 == 'DESC' ? 1 : 2;
      } else if (para1 === 'first_name') {
        this.ArrowAscFirstName = para2 == 'DESC' ? 1 : 2;
      } else if (para1 === 'last_name') {
        this.ArrowAscLastName = para2 == 'DESC' ? 1 : 2;
      } else if (para1 === 'location') {
        this.ArrowAscLocation = para2 == 'DESC' ? 1 : 2;
      }
      const filter = this.$store.getters.getOrderListFilter;
      filter.orderBy = para2;
      filter.orderByField = para1;
      this.$store.dispatch('setOrderListFilter', filter).then(() => {
        this.getAllOrders(null, 1);
      });
    },

    getAllOrders(
      selectedLocationId: any,
      page: any,
      startDate?: any,
      endDate?: any,
      searchNumber?: number,
      nodata = true,
    ) {
      console.log(page, 'page test');
      this.page = page;
      this.noData = nodata;
      const filter = this.$store.getters.getOrderListFilter;
      filter.pageSize = this.pageSize;
      filter.page = page;
      this.loading = true;
      this.$store.dispatch('setOrderListFilter', filter).then(() => {
        this.$store.dispatch('getOrderList').then(() => {
          if (!searchNumber || this.textCount == searchNumber) {
            let test = [];
            const filter = this.$store.getters.getOrderListFilter;
            this.filteredOrders = this.$store.getters.getOrderList;
            test = this.filteredOrders;
            this.loading = false;
            this.orders = this.filteredOrders;
            this.records = filter.totalCount;
            this.totalCount = Math.ceil(this.records / this.pageSize);
            this.currentPage = page || 1;
            this.nextPage = 0;
            if (this.filteredOrders.length) {
              this.noData = false;
              this.loading = false;
            }
          }
        });
      });
    },

    getPriceValue(value: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return value === null || Number.isNaN(value)
        ? '-'
        : formatter.format(value);
    },

    positiveButton() {
      this.showConfirm = false;
    },

    negativeButton() {
      this.showConfirm = false;
    },

    handleSearch_Field(event: any, field: string) {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        const value = event.target.value.trim();
        const filter = this.$store.getters.getOrderListFilter;
        filter.startDate =
          this.fromdate === null || this.fromdate === ''
            ? null
            : this.formatDate(this.fromdate);
        filter.endDate =
          this.todate === null || this.todate === ''
            ? null
            : this.formatDate(this.todate);
        filter.lastName =
          this.lastName.trim() !== '' ? this.lastName.trim() : null;
        filter.firstName =
          this.firstName.trim() !== '' ? this.firstName.trim() : null;
        this.$store.dispatch('setOrderListFilter', filter).then(() => {
          this.getAllOrders(null, 1, undefined, undefined, temp, false);
        });
      }, 1000);
    },

    pageEvent({ page }) {
      this.page = page;
      this.$store.commit('setOrderListFilter', { page });
      this.getAllOrders(this.selectedLocationId, page);
    },

    setPageSize(pageSize: number) {
      this.pageSize = pageSize;
      (this.page = 1),
        this.$store.commit('setOrderListFilter', { page: this.page, pageSize });
      this.getAllOrders(this.selectedLocationId, this.page);
    },
  },
  watch: {
    todate(value) {
      if (
        value === null ||
        value === '' ||
        this.fromdate === null ||
        this.fromdate === '' ||
        moment(value) < moment(this.fromdate)
      ) {
        this.applyEnable = true;
      } else {
        this.applyEnable = false;
      }
    },
    fromdate(value) {
      if (
        value === null ||
        value === '' ||
        this.todate === null ||
        this.todate === '' ||
        moment(value) > moment(this.todate)
      ) {
        this.applyEnable = true;
      } else {
        this.applyEnable = false;
      }
    },
  },
  computed: mapGetters(['transactions', 'transactionFilter']),
});
